import React from 'react'
import { Link } from 'gatsby'
import Codepen from '../../components/embedCodepen'
import Layout from '../../components/layout'
import Header from '../../components/header'
import { Helmet } from 'react-helmet';



const Portfolio1 = ({data}) => (
  <Layout>
      <Codepen/>
      <main className="page--demo demo--maptransform"> 
      <Helmet>
    <title>{data.site.siteMetadata.demoGridmapTitle} | {data.site.siteMetadata.title}</title>
      </Helmet>       
        <section className="slab slab--main">
          <Header siteTitle="Rob DiMarzo" /> 
            <div className="slab__inner">
                <div className="pageblock__content">  
                    <div className="pageblock__title">
                        <h1>Grid Map & List Transformation</h1>
                    </div>
                    <p className="text--bold">
                        A demo of how a single HTML source can be represented in different visual modes with CSS.
                    </p>
                    <p>The visual modes US states list into the following:</p>
                    <ol>
                        <li>
                            <strong>Map Mode</strong> - A geometric US map ordered by (general) geographic position  
                            <ul>
                                <li>
                                    Uses CSS Grid to position tiles using flex's order property and  grid-column for location.
                                </li>
                                <li>
                                    Abbreviations are displayed in the tile. Full name is displayed on hover via HTML title.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Tile Mode</strong> - A non-geographic tiled version
                            <ul>
                                <li>
                                    Uses CSS Grid ordered alphabetically in the DOM.
                                </li>
                                <li>
                                    Abbreviations are displayed in the tile. Full name is displayed on hover via HTML title.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>List Mode</strong> - A semantic HTML list of checkbox items
                            <ul>
                                <li>
                                    Semantic HTML checkbox list, ordered alphabeticlly in the DOM.
                                </li>
                                <li>
                                    Full name is displayed in the HTML
                                </li>
                            </ul>
                        </li>
                    </ol>
                    {/* <p>
                    How it works:
                    </p>
                    <ul> 
                        <li>
                            Each list item is a standard HTML checkbox and label
                        </li>                        
                        <li>
                            Each "mode" appends a class to the parent container, which allows for different styles to cascade accordingly.
                        </li>
                        <li>
                            I am using SCSS to provide 
                        </li>
                    </ul> */}

                </div>
                <div className="pageblock__demo">
                    <p data-height="700" data-theme-id="36208" data-slug-hash="XEyPvp" data-default-tab="result" data-user="robdimarzo" data-pen-title="US State List and Map Transformation" className="codepen">See the Pen <a href="https://codepen.io/robdimarzo/pen/XEyPvp/">US State List and Map Transformation</a> by Rob DiMarzo (<a href="https://codepen.io/robdimarzo">@robdimarzo</a>) on <a href="https://codepen.io">CodePen</a>.</p>
                </div>
            </div>  
        </section>



    </main>
  </Layout>
)

export const query = graphql`
  query GridMapQuery {
    site {
      siteMetadata {
        title
        demoGridmapTitle
      }
    }
  }
`
export default Portfolio1

